import { Injectable } from '@angular/core';
import { createTRPCProxyClient, httpBatchLink, TRPCClientError, TRPCLink } from '@trpc/client';
import type { TrpcRouter } from '../../../server/routes/trpc/router';
import { environment } from '../../environments/environment';
import { observable } from '@trpc/server/observable';

@Injectable({
  providedIn: 'root'
})
export class TrpcService {
  private readonly _client = createTRPCProxyClient<TrpcRouter>({
    links: [
      this.getErrorHandlerLink(),

      httpBatchLink({
        url: `${environment.server}/trpc`,

        // We send __session cookie
        // https://trpc.io/docs/client/cors
        fetch(url, options) {
          return fetch(url, {
            ...options,
            credentials: 'include',
          });
        },

        maxURLLength: 4096
      }),
    ],
  });

  get client(): typeof this._client {
    return this._client;
  }

  private getErrorHandlerLink(): TRPCLink<TrpcRouter> {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const handleError = (error: TRPCClientError<any>) => this.handleError(error);

    return () => {
      // here we just got initialized in the app - this happens once per app
      // useful for storing cache for instance
      return ({ next, op }) => {
        // this is when passing the result to the next link
        // each link needs to return an observable which propagates results
        return observable((observer) =>
          next(op).subscribe({
            next(value) {
              observer.next(value);
            },
            error(err) {
              handleError(err);
              observer.error(err);
            },
            complete() {
              observer.complete();
            },
          }));
      };

    };
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private handleError(error: TRPCClientError<any>) {
    if (!error.data || !error.shape) {
      return;
    }

    if (error.data.httpStatus === 500 && error.message === 'Server is in maintenance') {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      (window as any).MAINTENANCE = true;
      return;
    }
  }
}
