// This file can be replaced during build by using the `fileReplacements` array.

export const environment = {
  server: 'https://portal-dev-api.dev.learnswell.io',
  client: 'https://portal-dev.dev.learnswell.io',
  production: false,
  name: 'local',
  firebase: {
    apiKey: 'AIzaSyC3y_e2VRVZCNgtSiDihl_qVWN_-QdKMKg',
    authDomain: "portal-dev.dev.learnswell.io",
    projectId: "buildswell-dev"
  }
};
